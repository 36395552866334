@font-face {
  font-family: 'Figtree';
  src: url('https://fonts.googleapis.com/css?family=Figtree') format('woff2'); 
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Figtree", sans-serif, "Rubik", "Roboto", "Oxygen", "Ubuntu", "Cantarell", 
      "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  background: #fafafa;
    overflow: hidden;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}